<template>
  <div class="moretitle bg_fff">
    <div class="typeAll">
      <!-- 一级菜单 -->
      <div id="category1" class="box_color">
        <ul>
          <li
            :class="item.checked ? 'checked' : ''"
            v-for="(item, index) in treeCa"
            :key="index"
            @mouseenter="oneMouse(index)"
            @click="goGoodList(item.id)"
          >
            <span class="F14">{{ item.categoryName }}</span>
            <i class="el-icon-arrow-right"></i>
          </li>
        </ul>
      </div>
      <!-- 二级菜单 -->
      <div id="category2" class="box_color" v-show="twoShow">
        <div>
          <ul>
            <li
              :class="item.checked ? 'checked' : ''"
              v-for="(item, index) in twoShowList"
              :key="index"
              @mouseenter="twoMouse(index)"
              @mouseleave="twoLeave()"
              @click="goGoodList(item.id)"
            >
              <span class="F14">{{ item.categoryName }}</span>
              <i class="el-icon-arrow-right"></i>
            </li>
          </ul>
        </div>
      </div>
      <!-- 三级菜单 -->
      <div id="category3" class="box_color" v-show="threeShow">
        <div>
          <ul>
            <li
              @mouseenter="threeMouse(index)"
              :class="item.checked ? 'checked' : ''"
              v-for="(item, index) in threeShowList"
              :key="index"
              @click="goGoodList(item.id)"
            >
              <span class="F14">{{ item.categoryName }}</span>
              <i class="el-icon-arrow-right"></i>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "moreTitle",
  props: {
    treeCa: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  data() {
    return {
      twoShow: false,
      threeShow: false,
      twoShowList: [],
      threeShowList: [],
    };
  },
  methods: {
    oneMouse(index) {
      for (let x = 0; x < this.treeCa.length; x++) {
        if (index == x) {
          this.treeCa[index].checked = true;
          this.twoShowList = this.treeCa[index].children;
        } else {
          this.treeCa[x].checked = false;
        }
        var treeCa = this.treeCa[x];
        for (let j = 0; j < treeCa.children.length; j++) {
          treeCa.children[j].checked = false;
          var two = treeCa.children[j];
          for (let k = 0; k < two.children.length; k++) {
            two.children[k].checked = false;
          }
        }
        this.threeShow = false;
      }
      // 判断children是否有数据，有，则放入下一类别数组变量中
      if (this.treeCa[index].children.length !== 0) {
        this.twoShow = true;
      }
    },
    twoLeave() {
      if (this.threeShow !== true) {
        // this.twoShow = false;
      }
    },
    twoMouse(index) {
      for (let x = 0; x < this.twoShowList.length; x++) {
        if (index == x) {
          this.twoShowList[index].checked = true;
          this.threeShowList = this.twoShowList[index].children;
        } else {
          this.twoShowList[x].checked = false;
        }
        var two = this.twoShowList[x];
        for (let j = 0; j < two.children.length; j++) {
          two.children[j].checked = false;
        }
      }
      if (this.twoShowList[index].children.length !== 0) {
        this.threeShow = true;
      }
    },
    // threeMouse(index) {
    //   for (let x = 0; x < this.threeShowList.length; x++) {
    //     if (index == x) {
    //       this.threeShowList[index].checked = true;
    //     } else {
    //       this.threeShowList[x].checked = false;
    //     }
    //   }
    // },
    
    // 跳转商品列表页
    goGoodList(id) {
      this.$router.push({
        name: "MoreGoods",
        query: {
          typeId: id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.moretitle {
  text-align: left;
  .typeAll {
    display: flex;
    flex: 1;
    #category1,
    #category2,
    #category3 {
      width: 200px;
      // height: 250px;
      font-size: 14px;
      // overflow-y: scroll;
    }
    ul {
      padding: 0px;
    }
    li {
      display: flex;
      justify-content: space-between;
      padding: 5px 15px;
      cursor: pointer;
    }
    li:hover {
      color: #ff5b0c;
    }
    .checked {
      color: #ff5b0c;
      background-color: rgba(255, 91, 12, 0.1);
    }
  }
}
</style>
