<template>
  <div id="top" class="main-bg">
    <div class="contain">
      <div class="left">
        <slot name="tohome">
          <span
            style="margin-right: 2px"
            class="iconfont icon-gouwuchekong orange"
          ></span>
          <router-link :to="{ path: '/' }" class="orange"> 首页 </router-link>
        </slot>
      </div>
      <div class="right">
        <div>
          <slot name="name">
            <div class="name" style="display: flex; align-items: center">
              <a v-if="userInfo.id" @click="toInformation">欢迎您，{{ userInfo.nickName }}</a>
            </div>
            <div v-if="!userInfo.id" style="display: flex; align-items: center">
              <router-link :to="{ name: 'RoleChoose' }" class="orange"
                >免费注册</router-link
              >
            </div>
          </slot>
        </div>
        <div class="role" v-if="userInfo.id">
          <div class="userrole Mlf15 bg_09f">
            <span class="co_fff">{{ getRoleType(userInfo.roleType) }}</span>
          </div>
        </div>
        <div class="role" v-if="userInfo.id">
          <div class="userrole Mlf5" :style="{ background: userrole.bgColor }">
            <span class="co_fff">{{ userrole.role }}</span>
          </div>
        </div>
        <div class="Mlf40" style="display: flex; align-items: center">
          <router-link tag="a" :to="{name: 'MyOrder'}">我的订单</router-link>
        </div>
        <span class="main-text Mlf20" style="display: flex; align-items: center"
          >|</span
        >
        <div class="personal F12 main-text Mlf20" @click="toPersonal">
          <span>个人中心<i class="el-icon-arrow-down el-icon--right"></i></span>
        </div>
        <div class="Mlf40">
          <router-link
            v-if="!userInfo.id"
            :to="{ name: 'UserLogin' }"
            class="loginBtn bg-orange"
            tag="button"
            >立即登录</router-link
          >
          <button v-else class="loginBtn bg-orange" @click="logout">
            退出登录
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "top",
  data() {
    return {
      userrole: {
        state: false,
        bgColor: "green",
        role: "自然人",
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.getUserRole();
  },
  methods: {
    logout() {
      this.$store.dispatch("LogOut").then(() => {
        this.$router.push("/")
      });
    },
    // 跳转个人中心页面
    toPersonal() {
      // this.$router.push("/SupPurHome");
      // 判断角色跳转不同的页面
      if (this.userInfo.roleType == "1") {
        this.$router.push({ name: "MyAccount" });
      } else {
        this.$router.push({ name: "PurchaserMyMoney" });
      }
    },
    getUserRole() {
      if (this.userInfo.userType == "1") {
        this.userrole.state = true;
        this.userrole.bgColor = "green";
        this.userrole.role = "自然人";
      } else if (this.userInfo.userType == "2") {
        this.userrole.state = true;
        this.userrole.bgColor = "#FFE153";
        this.userrole.role = "个体户";
      } else if (this.userInfo.userType == "3") {
        this.userrole.state = true;
        this.userrole.bgColor = "blue";
        this.userrole.role = "企业";
      } else {
        this.userrole.state = false;
      }
    },
    getRoleType(roleType) {
      let arr = [ '', '供应商', '采购商' ];
      return arr[roleType];
    },
    toInformation() {
      this.$router.push({ name: "Information" });
    },
  },
};
</script>
<style lang="less" scoped>
.contain {
  display: flex;
  justify-content: space-between;
  padding: 7px;
  .right {
    display: flex;
    align-items: center;
  }
  .right {
    .name,
    .personal {
      cursor: pointer;
    }
    .userrole {
      padding: 2px 10px;
      span {
        display: table-cell;
      }
    }
    .loginBtn {
      color: #fff;
      padding: 5px 10px;
      border: none;
      font-size: 12px;
      cursor: pointer;
    }
  }
}
</style>
