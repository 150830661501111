<template>
  <div id="search">
    <div class="contain">
      <div class="wrap">
        <div class="searchleft">
          <img :src="websiteInfo.logo"
            class="wid100"
            style="vertical-align: middle">
          <span class="small co_primary Plf15">{{ websiteInfo.name }}</span>
        </div>
        <div class="searchright">
          <div class="bordertext">
            <div class="border">
              <div class="searchborder">
                <el-input
                  class="searchinput bd-orange"
                  placeholder="搜索你需要的商品"
                  v-model.trim="goodsName"
                  @keyup.enter.native="FTSSearch()"
                ></el-input>
              </div>
              <div class="searchicon bg-orange">
                <i class="el-icon-search curPointer" @click="FTSSearch"></i>
              </div>
            </div>
            <!-- <div class="text">
              <span>模板</span>
              <span>模板</span>
              <span>模板</span>
              <span>模板</span>
              <span>模板</span>
            </div> -->
          </div>
          <div class="shop">
            <router-link :to="{ name: 'ShopCart' }">
              <el-badge
                :value="shopCarInfo.total"
                class="item"
                :hidden="shopCarInfo.total == 0 ? true : false"
              >
                <button class="gouwu orange">
                  <span class="iconfont icon-gouwuchekong"></span>
                  我的购物车
                </button>
              </el-badge>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Search",
  data() {
    return {
      goodsName: "",
    };
  },
  computed: {
    ...mapGetters(["websiteInfo", "shopCarInfo"]),
  },
  methods: {
    FTSSearch() {
      if (this.goodsName == "") {
        this.$router.push({
          name: "index"
        });
      } else {
        this.$router.push({
          name: "FTSGoods",
          query: {
            goodsName: this.goodsName,
          },
        });
        this.$emit("FTSSearch", {
          goodsName: this.goodsName,
        });
      }
    },
  },
  watch: {
    $route: {
      handler(newRouter) {
        if(newRouter.name == "FTSGoods") {
          this.goodsName = newRouter.query.goodsName;
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="less" scoped>
#search {
  border-bottom: 1px solid #e5e5e5;
  .contain {
    padding: 30px 0;
    /*  border: 1px solid black; */
    .wrap {
      // overflow: hidden;
      display: inline-block;
      width: 100%;
      .searchleft {
        float: left;
        .small {
          font-size: 30px;
          vertical-align: middle;
          font-weight: bold;
        }
      }
      .searchright {
        float: right;
        // overflow: hidden;
        .bordertext {
          float: left;
          .border {
            overflow: hidden;
            .searchborder {
              width: 450px;
              float: left;
            }
            .searchicon {
              height: 42px;
              line-height: 42px;
              width: 50px;
              text-align: center;
              float: right;
              border-radius: 0 3px 3px 0;
              color: #fff;
              font-size: 18px;
            }
          }
          .text {
            width: 445px;
            padding: 8px 0 0 0;
            display: flex;
            justify-content: space-between;
            span {
              font-size: 13px;
              color: #777474;
            }
          }
        }
        .shop {
          float: right;
          margin-left: 10px;
          .gouwu {
            cursor: pointer;
            font-size: 15px;
            height: 42px;
            background-color: #fff;
            border: 1px solid#d2d2d2;
            line-height: 32px;
            span {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
</style>