<!--
 * @Author: Jerry
 * @Date: 2021-03-15 16:03:26
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-15 16:25:03
 * @Description: file content
-->
<template>
  <div class="contain">
    <div class="marquee">
      <div class="marquee_title">
        <span>通知公告</span>
      </div>
      <div class="marquee_box">
        <ul class="marquee_list" :class="{ marquee_top: animate }">
          <li v-for="(item, index) in marqueeList" :key="index">
            {{ item.title }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template> 
<script>
import { getSystemNotice } from "@/api/website.js";
export default {
  data() {
    return {
      animate: false,
      marqueeList: [],
    };
  },
  created: function () {
    this.getSystemNotice();
  },
  methods: {
    showMarquee: function () {
      this.animate = true;
      setTimeout(() => {
        this.marqueeList.push(this.marqueeList[0]);
        this.marqueeList.shift();
        this.animate = false;
      }, 500);
    },
    // 获取通知
    getSystemNotice() {
      getSystemNotice(5).then((res) => {
        this.$set(this, 'marqueeList', res.data);
        // 页面显示
        setInterval(this.showMarquee, 6000);
      });
    },
  },
};
</script>
 
<style scoped lang="scss">
.marquee {
  width: 100%;

  height: 50px;

  align-items: center;

  color: #3a3a3a;

  background-color: white;

  display: flex;

  box-sizing: border-box;

  overflow: hidden;
}

.marquee_title {
  padding: 0 20px;

  height: 21px;

  font-size: 14px;

  border-right: 1px solid #d8d8d8;

  align-items: center;
}

.marquee_box {
  display: block;

  position: relative;

  width: 60%;

  height: 30px;

  overflow: hidden;
}

.marquee_list {
  display: block;

  position: absolute;

  top: 0;

  left: 0;
}

.marquee_top {
  transition: all 0.5s;

  margin-top: -30px;
}

.marquee_list {
  margin: 0;
}
.marquee_list li {
  height: 30px;

  line-height: 30px;

  font-size: 12px;

  padding-left: 20px;
}
.marquee_list li span {
  padding: 0 2px;
}
</style>