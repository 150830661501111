<template>
  <div id="goodlist">
    <div class="wrap">
      <div class="header">
        <div class="toptitle">
          <slot name="title"></slot>
        </div>
        <div class="titleicon">
          <img src="@/assets/Login/titleicon.png" />
        </div>
        <!-- <a href="#" class="more">查看更多></a> -->
      </div>
      <div class="footer">
        <div class="goodsitem" v-for="(item, index) in goodsList" :key="index">
          <router-link tag="a" :to="{ name: 'GoodDetail', query: { 'id': item.goodsId } }" target="_blank">
            <div class="content">
              <div>
                <el-image
                  class="img"
                  :src="item.goodsLogo"
                  :fit="'fill'"
                ></el-image>
              </div>
              <span class="title"> {{ item.goodsTitle }}</span>
              <div class="money red">
                <span v-if="item.goodsPrice==0">面议</span>
                <span v-else>{{ item.goodsPrice }}</span>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "GoodList",
  props: {
    goodsList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    goDetail(id) {
      this.$emit("goDetail", id);
    },
  },
};
</script>
<style lang="less" scoped>
#goodlist {
  display: inline-block;
  width: 100%;
  padding-top: 25px;

  .wrap {
    display: inline-block;
    width: 100%;
    text-align: center;
    // border: 1px solid red;
    height: 340px;
    .header {
      height: 80px;
      position: relative;
      .toptitle {
        display: inline-block;
        font-size: 27px;
        padding: 20px;
        font-weight: 600;
      }
      .titleicon {
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translateX(-50%);
      }
      .more {
        position: absolute;
        right: 150px;
        bottom: 5px;
        color: #00f;
        font-size: 14px;
      }
    }
    .footer {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      .goodsitem {
        // display: inline-block;
        padding: 0px 10px;
        height: 260px;
        // border: 1px solid black;
        .content {
          cursor: pointer;
          background-color: #fff;
          height: 230px;
          width: 180px;
          padding-bottom: 20px;
          text-align: left;
          .img {
            margin: 15px 15px 0 15px;
            width: 150px;
            height: 150px;
          }
          .title {
            display: block;
            padding: 5px 15px 0px 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            /* 控制多行的行数 */
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 12px;
          }
          .money {
            padding: 5px 15px 0px 15px;
            span {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
</style>