<!--
 * @Author: hsn
 * @Date: 2021-01-22 15:41:57
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-15 16:04:41
 * @Description: file content
-->
<template>
  <div id="home">
    <top>
      <div slot="tohome"></div>
    </top>
    <search></search>
    <marquee ref="marquee"></marquee>
    <!--  轮播图  -->
    <slider
      class="banner"
      :pagination-visible="true"
      :slides="slides"
      :repeating="true"
      :auto="3000"
      @slide-change-start="onSlideChangeStart"
      @slide-change-end="onSlideChangeEnd"
      @slide-revert-start="onSlideRevertStart"
      @slide-revert-end="onSlideRevertEnd"
      @slider-move="onSliderMove"
    >
      <div v-for="(slide, index) in slides" :key="index">
        <a :href="slide.value">
          <img width="100%" :src="slide.path" />
        </a>
      </div>
    </slider>
    <!-- maintitle -->
    <div class="main">
      <div class="maintitle">
        <ul class="contain">
          <li class="mainitem" @mouseenter="moreEnter" @mouseleave="moreLeave">
            <div>
              <img :src="img" />
            </div>
            <a class="firsttitle">查看更多</a>
            <more-title
              class="moretitle"
              v-show="moreTitleShow"
              :treeCa="allGoodsTypeList"
            ></more-title>
          </li>
          <!-- :class="isActive == index ? 'bg_f4f4': ''" -->
          <li
            @mouseenter="enter(index)"
            @mouseleave="leave(index)"
            class="mainitem"
            v-for="(item, index) in goodsTypeList"
            :class="isActive == index ? 'bg_f4f4' : ''"
            :key="index"
          >
            <div @click="goGoodList(item.id)">
              <img :src="item.icon" style="height: 70px; width: 70px" />
            </div>
            <a class="firsttitle">{{ item.categoryName }}</a>
            <floating-menu
              class="hovermore box_color"
              v-show="item.isShow"
              :menu="item.children"
              :class="active > 1 ? 'right' : ''"
            ></floating-menu>
          </li>
        </ul>
      </div>
    </div>

    <div class="center main-bg">
      <good-list
        :goodsList="item"
        v-for="(item, index) in list"
        :key="index"
        @goDetail="goDetail"
      >
        <div slot="title">{{ index }}</div>
      </good-list>
    </div>
    <bottom :bgColor="'#ffffff'"></bottom>
  </div>
</template>
<script>
import Top from "@/components/top";
import Bottom from "@/components/bottom.vue";
import Search from "@/components/search.vue";
import Slider from "@/components/banner/homeBanner";
import GoodList from "@/components/home/goodList.vue";
import marquee from "@/components/home/marquee.vue";

import {
  shopIndexData,
  getShopIndexBanner,
  getGoodsType,
} from "@/api/website.js";
import FloatingMenu from "../components/floatingMenu/floatingMenu.vue";
import MoreTitle from "../components/floatingMenu/moreTitle.vue";

export default {
  name: "Home",
  components: {
    Top,
    Bottom,
    Search,
    Slider,
    GoodList,
    FloatingMenu,
    MoreTitle,
    marquee,
  },
  data() {
    return {
      active: -1,
      isActive: -1,
      twoShow: false,
      threeShow: false,
      moreTitleShow: false,
      moreShow: true,
      slides: [],
      moreVisible: false,
      list: [],
      goodsTypeList: [],
      allGoodsTypeList: [],
      floatMenu: [],
      img:
        "https://qzshop-test.oss-cn-beijing.aliyuncs.com/file_other/20210130145410330S43473_other.png",
    };
  },
  mounted() {
    this.shopIndexData();
    this.getShopIndexBanner();
    this.getGoodsType();
  },
  methods: {
    // 鼠标进入
    enter(index) {
      this.goodsTypeList[index].isShow = true;
      this.isActive = index;
      this.active = index;
    },
    //鼠标移除
    leave(index) {
      this.goodsTypeList[index].isShow = false;
      this.isActive = -1;
      console.log("离开的" + index);
    },
    moreEnter() {
      this.moreTitleShow = true;
    },
    moreLeave() {
      this.moreTitleShow = false;
    },
    // 获取下方列表数据
    shopIndexData() {
      shopIndexData(8).then((res) => {
        this.list = res.data;
      });
    },
    // 商品详情页
    goDetail(id) {
      this.$router.push({
        name: "GoodDetail",
        query: {
          id: id,
        },
      });
    },
    // 首页banner图
    getShopIndexBanner() {
      getShopIndexBanner().then((res) => {
        this.slides = res.data.records;
      });
    },
    // 获取分类
    getGoodsType() {
      getGoodsType().then((res) => {
        let goodsTypeList = res.data;
        this.$set(this, "allGoodsTypeList", goodsTypeList);
        if (goodsTypeList.length > 5) {
          for (let x = 0; x < 5; x++) {
            this.goodsTypeList.push(goodsTypeList[x]);
          }
        } else {
          this.$set(this, "goodsTypeList", goodsTypeList);
        }
      });
    },
    // 跳转商品列表页
    goGoodList(id) {
      this.$router.push({
        name: "MoreGoods",
        query: {
          typeId: id,
        },
      });
    },
    onSlideChangeStart: function (currentPage) {
      // console.log("onSlideChangeStart", currentPage);
    },
    onSlideChangeEnd: function (currentPage) {
      // console.log("onSlideChangeEnd", currentPage);
    },
    onSlideRevertStart: function (currentPage) {
      // console.log("onSlideRevertStart", currentPage);
    },
    onSlideRevertEnd: function (currentPage) {
      // console.log("onSlideRevertEnd", currentPage);
    },
    onSliderMove: function (offset) {
      // console.log("onSliderMove", offset);
    },
  },
};
</script>
<style lang="less" scoped>
#home {
  .main {
    .maintitle {
      position: relative;
      width: 1200px;
      margin: auto;
      .contain {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;
        .mainitem {
          margin-top: -4px;
          padding: 30px;
          cursor: pointer;
          text-align: center;
          // background-color: burlywood;
          .firsttitle {
            font-size: 16px;
            color: black;
          }
        }
      }
      .more {
        position: absolute;
        height: 77px;
        font-size: 14px;
        width: 25px;
        text-align: center;
        padding: 5px 1px;
        top: 36px;
        right: -10px;
        color: #898989;
        cursor: pointer;
      }
    }
  }
  .center {
    padding-bottom: 50px;
  }
}
.main .maintitle .hovermore,
.moretitle {
  position: absolute;
  z-index: 9;
  top: 152px;
}
// .main .maintitle .hovermore {
//   width: 1160px;
//   left: 50%;
//   transform: translateX(-50%);
// }
.main .maintitle .hovermore {
  margin-left: -30px;
  width: 500px;
}
.right {
  margin-left: -440px !important;
}
</style>
