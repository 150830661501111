<!--
 * @Author: hsn
 * @Date: 2021-02-02 17:17:34
 * @LastEditors: hsn
 * @LastEditTime: 2021-03-09 15:10:06
 * @Description: file content
-->
<template>
  <div class="floatingmenu Ptp15 Pbm20 Plf20 Prt20 bg_fff">
    <div v-for="(item, index) in menu" :key="index">
      <div class="F14 Mtp5 Mbm5">
        <span class="oneTitle" @click="goGoodList(item.id)" >{{ item.categoryName }}：</span>
        <span
          class="twoTitle Mrt10"
          v-for="(items, indexs) in item.children"
          :key="indexs"
          @click="goGoodList(items.id)"
          @mouseenter="enter(`${index},${indexs}`)"
          @mouseleave="leave()"
          :class="[isActive === `${index},${indexs}` ? 'orange' : '']" 
        >
        <!-- :class="[isActive === indexs ? 'orange' : '']" -->
          {{ items.categoryName }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    this.getMenu();
  },
  name: "FloatingMenu",
  props: {
    menu: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  data() {
    return {
      isActive: '-1,-1',
    };
  },
  methods: {
    getMenu() {
      console.log(this.menu);
    },
    enter(index) {
      this.isActive = index;
    },
    leave() {
      this.isActive = -1;
    },
    // 跳转商品列表页
    goGoodList(id) {
      this.$router.push({
        name: "MoreGoods",
        query: {
          typeId: id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.floatingmenu {
  text-align: left;
  .oneTitle {
    font-weight: bold;
  }
}
</style>
