<template>
  <div id="bottom" :style="{ backgroundColor: bgColor }">
    <div class="content">
      <div class="header b-bottom">
        <div class="contain">
          <div class="con">
            <div class="wrap" v-for="(item, index) in bottomTitle" :key="index">
              <el-image class="img" :src="item.image"></el-image>
              <span class="F18 Mlf15">{{ item.title }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="footer b-bottom co_333 F12">
        <div class="wrap">
          <div class="left">
            <div>
              <i class="el-icon-phone"></i>
              <span>{{ websiteInfo.mobile }}</span>
            </div>
            <div>
              <i class="el-icon-location-outline"></i>
              <span>{{ websiteInfo.address }}</span>
            </div>
            <div>
              <i class="el-icon-message"></i>
              <span>{{ websiteInfo.email }}</span>
            </div>
          </div>
          <div class="right">
            <img class="img" :src="websiteInfo.qrcode">
          </div>
        </div>
      </div>
      <div class="text co_666 F12">
        <span>{{ websiteInfo.copyright }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "bottom",
  data() {
    return {
      bottomTitle: [
        {
          image: "/img/icon/icon1.png",
          title: "专业建材辅材专家",
        },
        {
          image: "/img/icon/icon2.png",
          title: "品类齐全，轻松购买",
        },
        {
          image: "/img/icon/icon3.png",
          title: "正品行货，优质服务",
        },
      ],
    };
  },
  props: ["bgColor"],
  computed: {
    ...mapGetters(["websiteInfo"]),
  },
};
</script>
<style lang="less" scoped>
#bottom {
  background-color: #f3f3f3;
  .header .contain {
    padding: 30px;
    .con {
      display: flex;
      justify-content: space-around;
      .img {
        width: 80px;
        height: 80px;
        vertical-align: middle;
      }
      span {
        font-weight: bold;
        vertical-align: middle;
      }
    }
  }
  .footer {
    .wrap {
      width: 1100px;
      padding: 30px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      .left {
        div {
          padding: 3px;
          font-size: 14px;
          i {
            margin-right: 20px;
          }
        }
      }
      .right {
        position: absolute;
        right: 50px;
        top: 23px;
        .img {
          height: 88px;
          width: 88px;
        }
      }
    }
  }
  .text {
    text-align: center;
    line-height: 50px;
  }
}
</style>